<template>
  <div>
    <PageLoader :storage="appLoading" />
    <ServerError v-if="ServerError" />
    <v-snackbar v-model="showSnackBar" color="#FF6907" right :timeout="timeout">
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">
          <span style="color: #fff">{{ msg }}</span>
        </v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showSnackBar = false">
            <v-icon style="color: #fff">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout wrap justify-center v-if="topcommonsnakes && topcommonsnakes.length > 0">
      <v-flex xs12>
        <v-layout wrap justify-start pa-10>
          <v-flex xs12 sm6 md6 lg6 class="text-left">
            <span class="heading">Top Common Snakes</span>
          </v-flex>
          <v-flex xs12 sm6 md6 lg6 class="text-right">
            <download-excel
              :data="json_data"
              :fields="json_fields"
              name="filename.xls"
            >
              <span
                style="
                  font-size: 16px;
                  font-family: poppinssemibold;
                  color: green;
                  cursor: pointer;
                "
              >
                Download As Excel</span
              >
            </download-excel>
          </v-flex>
          <!-- <v-flex xs12 pt-3>
            <download-excel
              :data="json_data"
              :fields="json_fields"
              name="filename.xls"
            >
              <span
                style="
                  font-size: 16px;
                  font-family: poppinssemibold;
                  color: green;
                  cursor: pointer;
                "
              >
                Download As Excel</span
              >
            </download-excel>
          </v-flex> -->
          <!-- <v-flex xs12 pt-2>
            <v-layout wrap justify-start>
              <v-flex xs12 md6 sm6 lg6>
                <span class="subheading">Rows per page</span>
                <v-select
                  v-model="count"
                  color="#FF6907"
                  outlined
                  dense
                  hide-details
                  :items="[20, 30, 50]"
                ></v-select>
              </v-flex>
              <v-flex xs12 md6 sm6 lg6 pl-lg-2 pl-sm-2 pl-md-2>
                <span class="subheading">Filter</span>
                <v-text-field
                  outlined
                  hide-details
                  dense
                  placeholder="Type To Search"
                >
                </v-text-field>
              </v-flex>
            </v-layout>
          </v-flex> -->
          <v-flex xs12>
            <v-layout wrap justify-center>
              <v-flex xs12>
                <v-card>
                  <v-card-title>
                    <v-spacer></v-spacer>
                    <v-text-field
                      v-model="search"
                      append-icon="mdi-magnify"
                      label="Search"
                      outlined
                      dense
                      single-line
                      hide-details
                    ></v-text-field>
                  </v-card-title>
                  <v-data-table
                    :headers="headers"
                    :items="topcommonsnakes"
                    :search="search"
                  ></v-data-table>
                </v-card>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
    <v-layout wrap justify-start v-else>
      <v-flex xs12 class="text-center">
        <span class="heading">No Data Found!</span>
      </v-flex>
    </v-layout>
  </div>
</template>
  
  <script>
import axios from "axios";
export default {
  data() {
    return {
      appLoading: false,
      ServerError: false,
      showSnackBar: false,
      timeout: 5000,
      msg: null,
      pages: 0,
      count: 20,
      datalist: [],
      topcommonsnakes:[],
      json_data: [],
      json_fields: {
        Snakename: "snake.name",
        Count: "count",
      },
      json_meta: [
        [
          {
            key: "charset",
            value: "utf-8",
          },
        ],
      ],
      search: '',
        headers: [
          {
            text: 'Snake name',
            align: 'start',
            sortable: false,
            value: 'snake.name',
          },
          { text: 'Count', value: 'count' },
        ],
    };
  },
  beforeMount() {
    this.getData();
  },
  methods: {
    getData() {
      this.appLoading = true;
      axios({
        method: "GET",
        url: "/user/allstats",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          if (response.data.status == true) {
            {
              this.topcommonsnakes = response.data.data.topsnakesyear;
              this.json_data = response.data.data.topsnakesyear;
              this.appLoading = false;
            }
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
            this.appLoading = false;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          (this.ServerError = true), console.log(err);
        });
    },
  },
};
</script>
  <style>
.heading {
  font-family: poppinsregular;
  font-size: 20px;
  font-weight: 900;
}
.subheading {
  font-family: poppinsregular;
  font-size: 15px;
}
</style>
  